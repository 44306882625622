<template>
    <v-card flat>
        <v-card-title>
            <div class="secondary--text">
                <div class="d-flex align-center">
                    <span class="font-weight-bold">Tasks</span>
                    <v-btn :loading="fetchingUserTasks" icon @click="fetchTasks()"><v-icon>refresh</v-icon></v-btn>
                </div>
                <span v-if="fetchingUserTasks" class="caption text-uppercase">fetching tasks data...</span>
                <span v-else class="caption text-uppercase">{{ totalTasks }} tasks found</span>
            </div>
        </v-card-title>
        <v-card-text>
            <div class="d-flex align-center flex-wrap">
                <div v-for="(taskType, key) in taskCheckBoxData" :key="key">
                    <v-checkbox class="mx-4" v-model="taskType.value" @change="changeSelectedTasks">
                        <template v-slot:label>
                            <span class="secondary--text caption text-uppercase">{{ taskType.label }}</span>
                        </template>
                    </v-checkbox>
                </div>
            </div>
            <div>
                <v-data-table
                    :server-items-length="totalTasks"
                    :loading="fetchingUserTasks"
                    :headers="headers"
                    :items="userTasks"
                    :options.sync="options"
                    item-key="tkid"
                    :footer-props="{
                        itemsPerPageOptions
                    }"
                    no-data-text="No user tasks found">
                    <template v-slot:top>
                        <v-text-field
                            class="mb-5"
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Filter"
                            single-line
                            hide-details
                            outlined
                            dense></v-text-field>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" :color="getTaskChipColor(item.status)" left>
                                    {{ getTaskChipIcon(item.status) }}
                                </v-icon>
                                <v-btn @click="cancelThisTask(item)" v-if="taskIsCancellable(item.status)" fab small elevation="0">
                                    <v-icon color="red darken-2">close</v-icon>
                                </v-btn>
                            </template>
                            <span class="white--text">{{ taskTypeLabel(item.status) }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.operation`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <span class="text-truncate" v-on="on">{{ truncateText(item.operation, 60) }}</span>
                            </template>
                            <span>{{ item.operation }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.started`]="{ item }">
                        <div class="d-flex flex-column py-3">
                            <span v-if="item.started">
                                {{ item.started | dateTimeToHuman }}
                            </span>
                            <span v-else>-</span>
                            <span class="caption" v-if="item.finished">
                                <span class="font-weight-bold">Duration:</span>
                                {{ calculateDuration(item.finished, item.started) }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:[`item.result`]="{ item }">
                        <UserTaskOutcome :message="getItemMessage(item)"></UserTaskOutcome>
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { calculateDuration, truncateText } from '@/utils'
import { filter, debounce, find } from 'lodash'

const UserTaskOutcome = () => import('../components/TheUserTaskOutcome')

export default {
    mixins: [enumsData],
    components: { UserTaskOutcome },
    data() {
        return {
            search: '',
            options: {},
            params: {},
            searchInTasks: null,
            headers: [
                { text: 'Status', align: 'center', value: 'status', width: '80px' },
                {
                    text: 'Task',
                    value: 'operation',
                    width: '300px'
                },
                { text: 'Start time', value: 'started', width: '200px' },
                { text: 'Outcome', value: 'result', width: '50px', align: 'end', sortable: false }
            ],
            taskCheckBoxData: [
                {
                    key: 'RUNNING',
                    label: 'RUNNING',
                    value: true
                },
                {
                    key: 'QUEUED',
                    label: 'QUEUED',
                    value: true
                },
                {
                    key: 'COMPLETED',
                    label: 'COMPLETED',
                    value: true
                },
                {
                    key: 'CANCELLED',
                    label: 'CANCELLED',
                    value: true
                },
                {
                    key: 'FAILED',
                    label: 'FAILED',
                    value: true
                },
                {
                    key: 'CONTAINS_ERRORS',
                    label: 'CONTAINS ERRORS',
                    value: true
                },
                {
                    key: 'CREATED',
                    label: 'CREATED',
                    value: true
                },
                {
                    key: 'REJECTED',
                    label: 'REJECTED',
                    value: true
                }
            ],
            colors: ['warning', 'purple', 'brown', 'info', 'success', 'error', 'info', 'orange', 'red'],
            icons: ['error_outline', 'alarm_on', 'pause', 'mdi-timer-sand', 'done', 'error', '', 'error_outline', 'close']
        }
    },
    created() {
        this.changeSelectedTasks()
        this.$store.dispatch('userStore/fetchRunningUserTasks')
        this.searchInTasks = debounce(() => {
            this.params.operation = this.search
            this.fetchTasks()
        }, 700)
    },
    computed: {
        ...mapState('userStore', ['userTasks', 'fetchingUserTasks', 'totalTasks', 'maxTaskPerPage']),
        itemsPerPageOptions() {
            return [10, 20, 50, this.maxTaskPerPage]
        },
        selectedTasks() {
            const checkedTasks = filter(this.taskCheckBoxData, { value: true })
            return checkedTasks.length ? checkedTasks.map(task => this.taskStatusTypes[task.key].value).join('') : -1
        }
    },
    watch: {
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage, selectedTasks } = this.options
                this.params = {
                    page,
                    per_page: itemsPerPage,
                    status: selectedTasks,
                    operation: this.search
                }
                if (sortBy.length > 0) {
                    this.params.sort_by = sortBy
                    this.params.sort_desc = sortDesc
                }
                this.fetchTasks()
            },
            deep: true
        },
        search() {
            this.searchInTasks()
        }
    },
    methods: {
        fetchTasks() {
            this.$store.dispatch('userStore/fetchUserTasks', this.params)
        },
        changeSelectedTasks() {
            this.options.selectedTasks = this.selectedTasks
            this.options.page = 1
            this.options.per_page = this.itemsPerPageOptions[0]
        },
        taskTypeLabel(status) {
            return find(this.taskCheckBoxData, { key: this.taskStatusTypes.get(status).key }).label
        },
        calculateDuration: function (date2, date1) {
            return calculateDuration(date2, date1)
        },
        truncateText: function (text, length) {
            return truncateText(text, length)
        },
        getTaskChipColor: function (status) {
            return this.colors[status] || 'info'
        },
        getTaskChipIcon: function (status) {
            return this.icons[status] || 'info'
        },
        getItemMessage(item) {
            const resultMessages = [
                'Task has been rejected.',
                'Task created.',
                'Task is waiting in queue.',
                'Task is in progress.',
                'Task is completed.',
                'Task failed.',
                'Task is archived.',
                'Task encountered errors.',
                'Task cancelled'
            ]
            return item.result || resultMessages[item.status]
        },
        taskIsCancellable(status) {
            return [this.taskStatusTypes.QUEUED.value, this.taskStatusTypes.RUNNING.value, this.taskStatusTypes.CREATED.value].includes(status)
        },
        async cancelThisTask({ tkid }) {
            try {
                await this.$axios.post(`/tasks/${tkid}/cancel`)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Task successfully cancelled',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Failed to cancel task.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.$store.dispatch('userStore/fetchUserTasks', this.params)
        }
    }
}
</script>
